import Vue from 'vue'
import Router from 'vue-router'
Vue.use Router

import Reviewer from '~/components/app/Reviewer'

export default new Router
  mode: 'history'
  base: process.env.BASE_URL
  routes: [
    { path: '/r/:code', component: Reviewer }
  ]
