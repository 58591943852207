



export default
  props:
    icon: String
    weight: type: String, default: 'regular'

  computed:
    classes: ->
      type =
        solid:   'fas'
        regular: 'far'
        light:   'fal'
      "#{type[@weight] or 'fa'} fa-#{@icon or 'empty-set'}"
