






import Tippy from 'tippy.js/dist/tippy-bundle.esm'
import 'tippy.js/animations/scale-subtle.css'

export default
  props:
    content: String

  mounted: ->
    Tippy @$refs.trigger,
      animation: 'scale-subtle'
      content: @$refs.content
      duration: [150, 100]
      maxWidth: 300
      theme: 'tooltip'
