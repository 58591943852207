




























































































import Utils from '~/utils'
import Rating from './Rating'
import { tns } from 'tiny-slider/src/tiny-slider.js'

nonce = Utils.nonce(8)

export default
  name: 'Reviewer'

  components: {
    Rating
  }

  data: ->
    slider: null
    sliderIndex: 0
    submitted: false
    reviewer: {}
    assignments: []
    loading: true
    error: false

  computed:
    reviewsRemaining: ->
      remaining = @assignments.length
      for a in @assignments
        remaining -= 1 if a.rating.soft and a.rating.hard and a.rating.truthTeller and a.rating.teamPlayer and a.rating.goGetter
      return remaining

  watch:
    assignments:
      deep: true
      handler: (assignments) ->
        return if assignments.length is 0
        if @slider is null
          @$nextTick ->
            @slider = tns
              container: '.slider'
              autoWidth: true
              center: true
              loop: false
              arrowKeys: true
              controls: false
              nav: false
              speed: 200

            @slider.events.on 'indexChanged', (info, eventName) => @sliderIndex = info.index
        else
          a = assignments[@sliderIndex]
          if a.rating.soft and a.rating.hard and a.rating.truthTeller and a.rating.teamPlayer and a.rating.goGetter
            await @$api.post "response/#{@$route.params.code}",
              nonce: nonce
              revieweeId: a.revieweeId
              response: a.rating

  created: ->
    try
      # sample path: /r/4-1-84deb661
      response = await @$api.get "r/#{@$route.params.code}"
      @reviewer = response.data.reviewer
      @assignments = response.data.assignments
    catch error
      @error = true
    finally
      @loading = false

  methods:
    photoUrl: (url) ->
      if /^https?:\/\//i.test(url)
        url
      else
        "#{process.env.VUE_APP_SERVER_URL}/photos/#{url}"

    submit: -> @submitted = true
