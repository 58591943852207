import Vue from 'vue'
import router from './router'
import './axios'
Vue.config.productionTip = false

# global styles
import '@fortawesome/fontawesome-pro/css/all.css'
import './styles/app.styl'

# global components
import globalComponents from './components/global/*.vue'
for name, code of globalComponents
  Vue.component name, code.default or code

import App from '~/components/app/App'

window.p = (args...) -> console.log arg for arg in args
new Vue
  router: router
  render: (h) => h App
.$mount '#app'
