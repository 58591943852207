







export default
  props:
    context: String

  computed:
    classes: ->
      'global':  @context is 'global'
      'content-panel': @context is 'contentPanel'
