





















export default
  props:
    type: type: String, default: 'default'
    label: String
    icon: String
    iconPosition: String
    iconWeight: type: String, default: 'solid'
    iconSize: String
    size: String
    active: Boolean
    success: Boolean
    disabled: Boolean
    loading: Boolean
    boxShadow: type: Boolean, default: true

  computed:
    classes: ->
      'default':         @type is 'default'
      'primary':         @type is 'primary' || @type is 'submit'
      'secondary':       @type is 'secondary'
      'dialog':          @type is 'dialog'
      'icon':            @icon
      'icon-only':       @icon && !@label
      'icon-left':       @iconPosition is 'left'
      'icon-right':      @iconPosition is 'right'
      'active':          @active
      'success':         @success
      'no-shadow':      !@boxShadow
      'loading':         @loading
