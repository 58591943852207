

























export default
  props:
    description: String
    input: Number
    label: String

  data: ->
    stars: []

  watch:
    input:
      immediate: true
      handler: (input) -> @$nextTick -> @updateStars input, 'click'

  created: ->
    maxRating = 5
    for rating in [1..maxRating]
      @stars.push
        rating: rating
        selected: false
        highlighted: false

  methods:
    updateStars: (rating, event) ->
      for s in @stars
        s.highlighted = false
        if event is 'click'
          s.selected = if rating is 5 then s.rating is rating else s.rating <= rating
        else if event is 'mouseover'
          s.highlighted = if rating is 5 then s.rating is rating else s.rating <= rating

    onClick: (star) ->
      @updateStars star.rating, 'click'
      @$emit 'update:input', star.rating

    onMouseover: (star) ->
      @updateStars star.rating, 'mouseover'

    onMouseleave: ->
      for s in @stars
        s.highlighted = false
